import React, { ReactElement } from "react"
import { Timeline as AntTimeline } from "antd"
import "./Timeline.scss"
import { TimelineItem } from "./TimelineItem"
import { TimelineIcon } from "./TimelineIcon"
import { timelineItems } from "./timelineItems"

const Timeline = (): ReactElement => {
  return (
    <div className="Timeline">
      <AntTimeline>
        {timelineItems.map((item, id) => (
          <AntTimeline.Item key={id} dot={<TimelineIcon type={item.type} />}>
            <TimelineItem item={item} />
          </AntTimeline.Item>
        ))}
      </AntTimeline>
    </div>
  )
}

export default Timeline
