import React, { ReactElement } from "react"
import { AiOutlineSafetyCertificate, IoBriefcaseOutline, IoFlaskOutline, IoBookOutline } from "react-icons/all"
import "./TimelineIcon.scss"
import { TimelineItemType } from "./TimelineItem"

const args = {
  className: "TimelineIcon"
}

export const TimelineIcon = ({ type }: { type: TimelineItemType }): ReactElement | null => {
  switch (type) {
    case TimelineItemType.Job:
      return <IoBriefcaseOutline {...args} />
    case TimelineItemType.Edu:
      return <IoBookOutline {...args} />
    case TimelineItemType.Cert:
      return <AiOutlineSafetyCertificate {...args} />
    case TimelineItemType.Project:
      return <IoFlaskOutline {...args} />
    default:
      return null
  }
}
