import React, { ReactElement } from "react"
import "./Footer.scss"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { IoArrowUpCircleOutline, IoLogoGithub, IoLogoLinkedin } from "react-icons/all"
import { Tooltip } from "antd"

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  })
}

const Footer = (): ReactElement => {
  return (
    <div className="Footer">
      <div className="Footer__BaLogo">
        <img alt="logo" src="/favicon.svg" />
      </div>
      <OutboundLink href="https://github.com/bartoszadamczyk">
        <div className="Footer__GitHubLogo">
          <IoLogoGithub />
        </div>
      </OutboundLink>
      <OutboundLink href="https://www.linkedin.com/in/bartoszadamczyk/">
        <div className="Footer__LinkedInLogo">
          <IoLogoLinkedin />
        </div>
      </OutboundLink>
      <Tooltip placement="right" title="Scroll to top">
        <div className="Footer__ScrollToTopLogo" role="presentation" onClick={scrollToTop}>
          <IoArrowUpCircleOutline />
        </div>
      </Tooltip>
    </div>
  )
}

export default Footer
