import { TimelineItem, TimelineItemType } from "./TimelineItem"

export const timelineItems: Array<TimelineItem> = [
  {
    name: "Engineering Team Lead at Moo",
    type: TimelineItemType.Job,
    period: "July 2023 - Present"
    // techStack:
    //   "TypeScript, React, Next.js, Emotion, Jest, Cypress, GraphQL, Apollo, Netlify, REST API, Stripe SDK, Node.js, Serverless, Terraform, Agile, Jira, GitHub, Datadog, Snowflake, AWS (API Gateway, Lambda, S3, SQS)",
    // hiddenStack: "JavaScript, HTML, CSS, SQL"
  },
  {
    name: "Senior Software Engineer at Moo",
    type: TimelineItemType.Job,
    period: "July 2021 - June 2023"
    // techStack:
    //   "TypeScript, React, Jest, Cypress, Netlify, REST API, Stripe SDK, Node.js, Serverless, Terraform, Agile, Jira, GitHub, Datadog, Snowflake, AWS (API Gateway, Lambda, S3, SQS)",
    // hiddenStack: "JavaScript, HTML, CSS, SQL"
  },
  {
    type: TimelineItemType.Cert,
    name: "HashiCorp Certified: Terraform Associate (002)",
    period: "September 2022",
    description:
      "The Terraform Associate certification is for Cloud Engineers specializing in operations, IT, or development who know the basic concepts and skills associated with open source HashiCorp Terraform."
  },
  {
    name: "Pico IR",
    type: TimelineItemType.Project,
    period: "January 2022",
    techStack: "Python, MicroPython, Raspberry Pi Pico",
    links: [
      {
        name: "[GitHub] bartoszadamczyk/pico-ir",
        url: "https://github.com/bartoszadamczyk/pico-ir"
      },
      {
        name: "[GitHub] awesome-micropython#ir",
        url: "https://github.com/mcauser/awesome-micropython#ir"
      }
    ]
  },
  {
    type: TimelineItemType.Cert,
    name: "AWS Certified Solutions Architect - Associate - Recertification",
    period: "December 2021",
    description:
      "The AWS Certified Solutions Architect - Associate examination is intended for individuals who perform a solutions architect role and have one or more years of hands-on experience designing available, cost-efficient, fault-tolerant, and scalable distributed systems on AWS."
  },
  {
    name: "Senior Product Engineer at flatfair",
    type: TimelineItemType.Job,
    period: "October 2019 - July 2021",
    techStack:
      "TypeScript, Vue, React, Jest, Python, Django, REST API, Stripe SDK, HelloSign SDK, unittest, pytest, Node.js, Serverless, SendGrid SDK, PostgreSQL, NGINX, Docker, Terraform, Agile, Clubhouse.io, GitHub, CircleCI, Sentry, Datadog, AWS (CloudFront, Route 53, ELB, EC2, Elastic Beanstalk, VPC, Lambda, S3, SQS, RDS, Cloud Formation, IAM)",
    hiddenStack: "JavaScript, HTML, CSS, SQL",
    description:
      "I was responsible for the hands-on, end-to-end implementation of the product roadmap milestones. For several projects, I was working as a dedicated project lead. Responsible for: communication with Product Managers and Designers, writing RFC, splitting tasks, conducting estimation sessions, planning sprints, assigning work to multiple developers, scoping work for deadlines, overseeing the delivery, planning deployment and reporting to technical managers and CTO. For some projects, we had dedicated frontend and backend developers, however, for most of the milestones we had to work as full-stack developers."
  },
  {
    name: "Weather Station based on Raspberry Pi and AWS",
    type: TimelineItemType.Project,
    period: "February 2021 - April 2021",
    techStack:
      "TypeScript, React, Immer, i18next, Ant Design, Jest, Netlify, Serverless, WebSockets, AJV, Mocha, Chai, Sinon.js, Python, AsyncIO, boto3, Mypy, unittest, pytest, balena.io, Docker, Raspberry Pi, Terraform, GitHub, GitHub Actions, Sentry, AWS (SQS, DynamoDB, API Gateway, IAM)",
    hiddenStack: "JavaScript, HTML, CSS, SQL",
    description:
      "The project objectives were to create a way to remotely monitor countryside house temperature with Raspberry Pi and sensors like DS18B20, and to automate the control of emergency heating with relays and GPIOs, to protect water pipes in the winter. The POC was ready in under two weeks, however later on I decided to push the project further and use it as a sandbox to broaden my skill set.",
    links: [
      {
        name: "[GitHub] bartoszadamczyk/weather-station-cloud",
        url: "https://github.com/bartoszadamczyk/weather-station-cloud"
      },
      {
        name: "[GitHub] bartoszadamczyk/weather-station-rpi",
        url: "https://github.com/bartoszadamczyk/weather-station-rpi"
      }
    ]
  },
  {
    name: "Glowing Darcula Theme for JetBrains IDEs",
    type: TimelineItemType.Project,
    period: "January 2021 - February 2021",
    description:
      "Glowing Darcula is a high contrast theme based on the iconic Darcula theme from JetBrains. The Glowing Darcula Theme is excellent for sunny days when working outside or close to a window. The theme provides a smaller contrast between the dark mode editor and other light windows outside the IDE. Thoroughly implemented, with attention to detail, it provides a stable addition to JetBrains IDE. In addition to improving almost every single colour, Glowing Darcula brings beautiful blue accents to the Editor Tabs, Progress Bar and Buttons.",
    links: [
      {
        name: "[JetBrains Marketplace] Glowing Darcula",
        url: "https://plugins.jetbrains.com/plugin/15905-glowing-darcula"
      },
      {
        name: "[GitHub] bartoszadamczyk/glowing-darcula",
        url: "https://github.com/bartoszadamczyk/glowing-darcula"
      }
    ]
  },
  {
    type: TimelineItemType.Job,
    name: "Solutions Architect at Adludio",
    period: "November 2016 - October 2019",
    techStack:
      "JavaScript, React, Redux, Jest, Cypress, Node.js, Express, REST API, Stripe SDK, Trello SDK, Python, boto3, MySQL, Redis, Elasticsearch, Hive, Hadoop, Docker, Terraform, Agile, Jira, Trello, Google Workspace Admin, GitHub, CircleCI, AWS (CloudFront, Route 53, ELB, EC2, ECS, VPC, Lambda, S3, SQS, SNS, CloudTrail, Config, RDS, DynamoDB, Athena, EMR, Kinesis Firehose, Kinesis Stream, Kinesis Analytics, Machine Learning, Cloud Formation, IAM, Billing)",
    description:
      "I joined Adludio as a Senior Developer. After two years, in line with responsibilities, the role evolved to become Solutions Architect. Responsible for: Representing the company at the investors' technical and security audits. Interviewing candidates, mentoring and training junior developers. Managing and delivering multiple company-wide projects. Designing Company Cloud Architecture, Big Data pipelines and aggregations for reporting systems. Technical implementation of GDPR and technical documentation."
  },
  {
    type: TimelineItemType.Cert,
    name: "AWS Certified Solutions Architect - Associate",
    period: "December 2018",
    description:
      "The AWS Certified Solutions Architect - Associate examination is intended for individuals who perform a solutions architect role and have one or more years of hands-on experience designing available, cost-efficient, fault-tolerant, and scalable distributed systems on AWS."
  },
  {
    type: TimelineItemType.Job,
    name: "Cloud Consultant at Eyn",
    period: "July 2018 - December 2018 - Part-time",
    techStack: "MySQL, Docker, Terraform, CircleCI, AWS (ELB, EC2, ECS, VPC, DynamoDB, RDS, Aurora, Cloud Formation)",
    description:
      "I was asked to help an early-stage startup with their cloud infrastructure. My main focus was to train staff including CTO in AWS products, SQL and NoSQL database principles, DevOps methodologies and tools applicable to the nature of the business. Within four months I helped staff to pass multiple AWS certifications at an associate level. The training took place during weekends."
  },
  {
    type: TimelineItemType.Cert,
    name: "AWS Certified Cloud Practitioner",
    period: "May 2018",
    description:
      "The AWS Certified Cloud Practitioner examination is intended for individuals who have the knowledge and skills necessary to effectively demonstrate an overall understanding of the AWS Cloud, independent of specific technical roles addressed by other AWS Certifications."
  },
  {
    type: TimelineItemType.Job,
    name: "Full-stack Developer at JKU & Associates",
    period: "June 2015 - November 2016",
    techStack:
      "JavaScript, AngularJS, HTML, CSS, D3, Node.js, Express, REST API, Python, boto3, MySQL, Elasticsearch, Docker, OpenVPN, Agile, Asana, Bitbucket, AWS (Route53, EC2, VPC, Lambda, S3, SQS, SNS, SES, DynamoDB, API Gateway, IAM)",
    description:
      "As a core part of the startup development team, I worked on multiple products and tools end-to-end. I had an opportunity to shape the early-stage tech stack of the company and learn how to pick the best tools for the assigned projects. Responsible for: solutions design, architecture, API design, implementation, deployment and maintenance."
  },
  {
    type: TimelineItemType.Edu,
    name: "MEng Computer Science at University College London",
    period: "September 2010 - May 2015",
    techStack: "Python, Java, C, Assembler, MATLAB, SQL, DNS, Wireless systems, OSI Model, Linux, Bash, Git",
    description:
      "The degree had a strong focus on solving real-world problems combined with building a deep understanding of computer science theory. In addition to multiple research and group projects, my main focus was on the Security of Distributed Systems, Networked Systems, Computer Security and Relational Database Management Systems."
  },
  {
    type: TimelineItemType.Job,
    name: "Web Developer at University College London",
    period: "June 2012 - May 2015 - Part-time (2 days per week)",
    techStack: "JavaScript, HTML, CSS, Photoshop, jQuery, REST API, PHP, MySQL, Agile",
    description:
      "In response to a previous successful project, UCL cloud, I was invited to a summer internship at UCL Web and Mobile Services. After six weeks I was offered a part-time Web Developer position for the rest of my studies. I was responsible for implementing templates in UCL’s custom CMS as well as creating data pipelines, together with Web Architects, Content Managers and Graphic Designers."
  },
  {
    type: TimelineItemType.Job,
    name: "Vice President IT at UCL Enterprise Society",
    period: "September 2012 - May 2015",
    techStack:
      "JavaScript, HTML, CSS, Photoshop, jQuery, WordPress, PHP, MySQL, Mailchimp, Google Workspace Admin, DNS, Shared Web Hosting",
    description:
      "Provided the infrastructure of domains, websites, servers and emails for one of the biggest entrepreneurial societies in the UK."
  },
  {
    type: TimelineItemType.Project,
    name: "Final Year Project at University College London",
    period: "October 2014 - April 2015",
    techStack: "Java, C, Android, Java Native Interface",
    description:
      "The project focused on the development of an Android-based spectrum analyser of TV White Spaces that would analyse, store and geo-tag the scans performed with low-cost devices. Spectrum maps are used for the planning of long-distance wireless networks using frequencies that are not used by the TV."
  },
  {
    type: TimelineItemType.Job,
    name: "Freelancing for multiple clients",
    period: "September 2013 – December 2014",
    techStack: "JavaScript, HTML, CSS, Photoshop, PHP, MySQL, Shared Web Hosting, AWS (S3)",
    description:
      "I have delivered multiple custom build websites like home pages of charities, small organizations and professionals."
  },
  {
    type: TimelineItemType.Project,
    name: "TEDxUCL 2014",
    period: "April 2014",
    description:
      "Theme: Global Citizenship. In the spirit of ideas worth spreading, TEDx is a program of local, self-organized events that bring people together to share a TED-like experience. At a TEDx event, TEDTalks video and live speakers combine to spark deep discussion and connection in a small group. These local, self-organized events are branded TEDx, where x = independently organized TED event. The TED Conference provides general guidance for the TEDx program, but individual TEDx events are self-organized (subject to certain rules and regulations)."
  },
  {
    type: TimelineItemType.Project,
    name: "Research Group Project at University College London",
    period: "October 2013 - April 2014",
    techStack: "Python, MongoDB",
    description:
      "With three other students, we worked on Task Identification using Search Engine Query Logs. By stemming and linking 8GB of AOL Search Engine Query Logs and knowledge bases like Freebase and YAGO, we were able to cluster the queries and deliver meaningful connections."
  },
  {
    type: TimelineItemType.Project,
    name: "London Student Enterprise Hub",
    period: "February 2014",
    description:
      "There are countless daily enterprise events taking place in London. We feel that a lot of these events are organized without a clear objective. As a body that aims to support student start-ups, we want to run student-friendly enterprise events that will provide an all round start-up experience to up and coming young entrepreneurs. We run three free events per year taking place on term-time weekends where we host talks, workshops, start-up showcase and demonstrations. Start-ups also have the opportunity to pitch for a seed investment sponsored by UCL Enterprise Society and increase their exposure. There are also ample opportunities to network and meet the key players in the London start-up scene."
  },
  {
    type: TimelineItemType.Project,
    name: "UCLcloud.com",
    period: "November 2011 - March 2013",
    techStack: "HTML, CSS, Photoshop, PHP, MySQL, Shared Hosting",
    description:
      "Created an unofficial navigation website with links for UCL students to all university web services. The website solved the university’s distributed structure problems, resulting in 450,000+ page views and 37,000+ visitors within 17 months. The website was later incorporated into the UCL homepage.",
    links: [
      {
        name: "[PDF] Pi Magazine: Interview about UCL cloud",
        url: "https://ba-com-prod-eu-central-1-assets.s3.eu-central-1.amazonaws.com/o8yMXZFF4CyCUYF7y1SI9eNurtC78N7k/Pi-Mag-692-uclcloud.pdf"
      }
    ]
  },
  {
    type: TimelineItemType.Edu,
    name: "VIII High School “Marii Skłodowskiej-Curie”, Katowice, Poland",
    period: "September 2007 - June 2010",
    techStack: "Pascal, MS Excel, MS Word, MS Access, Algorithms, OSI Model",
    description:
      "Three year High School with a focus on Mathematics, Physics and Computer Science ended with Polish Baccalaureate in advanced Mathematics, advanced Computer Science and advanced English Language."
  },
  {
    type: TimelineItemType.Project,
    name: "High School forum for students",
    period: "September 2007 - June 2010",
    techStack: "Photoshop, phpBB, MySQL, Shared Web Hosting",
    description:
      "In the first month of High School, I set up a communication platform for students based on the phpBB framework. The website was welcomed by many and used heavily for the next 3 years."
  },
  {
    type: TimelineItemType.Job,
    name: "Freelancing for multiple clients",
    period: "September 2008 – May 2010",
    techStack: "JavaScript, HTML, CSS, Photoshop, PHP, MySQL, Shared Web Hosting",
    description:
      "I have delivered multiple custom build websites like home pages of charities, small organizations and professionals."
  },
  {
    type: TimelineItemType.Project,
    name: "Award-winning website for the nationwide competition",
    period: "September 2006 - June 2007",
    techStack: "JavaScript, HTML, CSS, Photoshop, PHP, MySQL, Shared Web Hosting",
    description: "I created a custom-made website that was later filled together with a group of students and teachers."
  },
  {
    type: TimelineItemType.Edu,
    name: "My first line of code ✌️",
    period: "March 2006",
    techStack: "HTML, CSS"
  }
]
