import React from "react"
import { Helmet } from "react-helmet"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import FullPageWrapper from "../components/FullPageWrapper"
import Hero from "../components/Hero"
import Timeline from "../components/Timeline"
import Footer from "../components/Footer"
import "antd/dist/antd.css"
import "./main.scss"

const primaryHeader = "Hi, I'm Bartek"
const gitHubLink = <OutboundLink href="https://github.com/bartoszadamczyk">GitHub</OutboundLink>
const linkedInLink = <OutboundLink href="https://www.linkedin.com/in/bartoszadamczyk/">LinkedIn</OutboundLink>
const secondaryHeader = (
  <>
    Check out my {gitHubLink} and {linkedInLink} profiles
  </>
)

const Home = () => {
  return (
    <div className="Home">
      <Helmet defer={false}>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Hi, I&#39;m Bartek</title>
        <meta name="author" content="Bartosz Adamczyk" />
        <meta property="og:title" content="Hi, I'm Bartek" />
        <meta property="og:type" content="Homepage" />
        <meta property="og:url" content="https://bartoszadamczyk.com" />
        <meta property="og:image" content="https://bartoszadamczyk.com/preview.png" />
        <meta
          name="description"
          property="og:description"
          content="Senior Product Engineer with a history of managing business-critical projects. Experienced in interviewing, mentoring and training developers. Skilled in AWS, TypeScript, Python, React, Databases, Microservices, DevOps and Agile. AWS Certified Solutions Architect with an MEng in Computer Science from UCL."
        />
      </Helmet>
      <FullPageWrapper>
        <Hero primaryHeader={primaryHeader} secondaryHeader={secondaryHeader} />
        <Timeline />
        <Footer />
      </FullPageWrapper>
    </div>
  )
}

export default Home
