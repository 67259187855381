import React, { ReactElement } from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { Typography } from "antd"
import "./TimelineItem.scss"
const { Text, Title } = Typography

export enum TimelineItemType {
  Job = "job",
  Edu = "edu",
  Cert = "cert",
  Project = "project"
}

export type LinkItem = {
  name: string
  url: string
}

export type TimelineItem = {
  type: TimelineItemType
  name: string
  period: string
  techStack?: string
  hiddenStack?: string
  description?: string
  links?: NotEmptyArray<LinkItem>
}

const TechStack = ({ techStack }: { techStack: string }) => {
  return (
    <div className="TimelineItem__techStack">
      <code>{techStack}</code>
    </div>
  )
}

const Description = ({ description }: { description: string }) => (
  <div className="TimelineItem__description">
    <Text>{description}</Text>
  </div>
)

const Links = ({ links }: { links: NotEmptyArray<LinkItem> }) => (
  <div className="TimelineItem__links">
    <ul>
      {links.map(({ url, name }) => (
        <li key={url}>
          <OutboundLink href={url}>{name}</OutboundLink>
        </li>
      ))}
    </ul>
  </div>
)

export const TimelineItem = ({ item }: { item: TimelineItem }): ReactElement => {
  const { name, period, techStack, description, links } = item
  return (
    <div className="TimelineItem">
      <Title level={3}>{name}</Title>
      <Title level={4}>{period}</Title>
      {techStack && <TechStack techStack={techStack} />}
      {description && <Description description={description} />}
      {links && <Links links={links} />}
    </div>
  )
}
